// @flow

import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import cn from 'classnames';
import { type EnhancedProps, withTransition } from 'client/components/common/HOCs/with-transition';
import { parseSearch } from 'common/utils/parse-react-router-search';
import { dataLayerEvents } from 'common/utils/gtag';
import { updateLikes } from 'api';
import { getSlides as getSlidesAction } from 'client/actions';
import { COLLECTIONS, IS_DEVEL } from 'client/constants/common';
import { CAN_USE_DOM } from 'common/constants';
import { ROOT_ROUTE, GROUP_OF_BRANDS_PAGE_ROUTE } from 'client/constants/routes';
import { Header, SidePanel } from 'client/components/common';
import { fileLink } from 'common/utils';

import { Helmet } from 'react-helmet-async';
import faviconImg from 'assets/favicon.png';
import { collection, doc, onSnapshot } from 'firebase/firestore';
import { database } from '../../../../../common/firebase';
import Slide from '../../common/Slide';

import * as css from './BrandPage.scss';

type Props = {
	...EnhancedProps,
	in: boolean,
	slides: Array<SlideType>,
	currentBrand: BrandType,
	getSlides: typeof getSlidesAction,
	location: RouterLocation,
	history: RouterHistory,
	groupUrl: string,
};
const BrandPage = (props: Props) => {
	const {
		slides,
		getSlides,
		currentBrand,
		location,
		history,
		groupUrl,
		onTransitionComplete,
		transitionState,
	} = props;
	let transitionCompleteTimeout;

	const [slideIndex, setSlideIndex] = useState(0);
	const [isWelcome, setIsWelcome] = useState(true);
	const [neededSlides, setNeededSlides] = useState([]);
	const [likes, setLikes] = useState(0);

	useEffect(() => {
		slideIndexOnStart();
		createSlidesData();
		transitionCompleteTimeout = setTimeout(onTransitionComplete, parseInt(css.transitionIn, 10));
	}, []);

	useEffect(() => {
		if (props.in) {
			let duration = parseInt(props.in ? css.transitionIn : css.transitionOut, 10);

			if (!props.in) {
				duration = 0;
			}
			clearTimeout(transitionCompleteTimeout);
			transitionCompleteTimeout = setTimeout(onTransitionComplete, duration);
		}
	}, [props.in]);

	useEffect(() => {
		if (history) {
			getSlides({ collection: COLLECTIONS.SLIDES, id: currentBrand.id });
		}
	}, [history]);

	useEffect(() => {
		createSlidesData();
		const id = _.get(currentBrand, 'id');
		const brandsCollection = collection(database, 'brands');

		const unsubscribe = onSnapshot(doc(brandsCollection, id), docum => {
			const data = docum.data();
			const curBrandLikes = _.get(data, 'likes');
			setLikes(curBrandLikes);
		});

		return () => {
			unsubscribe();
		};
	}, [slides, currentBrand]);

	useEffect(() => {
		if (CAN_USE_DOM && !IS_DEVEL) {
			const { origin, pathname } = window.location;
			const slide = slideIndex === 0 ? 'main' : slideIndex;
			const labelSlideName = slideIndex === 0 ? 'ראשי' : slideIndex;
			const url = `${origin}${pathname}/${slide}`;
			dataLayerEvents.pageView(url, `${currentBrand.title} - ${labelSlideName}`);
		}
		history.push({ pathname: `${location.pathname}`, search: `?s=${slideIndex}` });
	}, [slideIndex]);

	const createSlidesData = () => {
		const brand = {
			brandId: currentBrand.id,
			id: currentBrand.id,
			title: currentBrand.title,
			imageDesktop: currentBrand.imageDesktop,
			imageMobile: currentBrand.imageMobile,
			likes: currentBrand.likes,
			createdDate: currentBrand.createdDate,
			subtitle: '',
		};

		setNeededSlides([brand, ...slides]);
	};

	const slideIndexOnStart = () => {
		const searchParams = parseSearch(location.search);

		if (_.isEmpty(searchParams)) {
			return;
		}
		const slideIndexQuery = +searchParams.s;

		if (slideIndexQuery) setIsWelcome(false);
		setSlideIndex(slideIndexQuery);
	};

	const nextSlide = () => {
		if (isWelcome) setIsWelcome(false);
		setSlideIndex(slideIndex + 1);
		if (!IS_DEVEL) dataLayerEvents.clickOnSlideButton('Next', currentBrand.title);
	};
	const previousSlide = () => {
		if (slideIndex === 1) setIsWelcome(true);
		setSlideIndex(slideIndex - 1);
		if (!IS_DEVEL) dataLayerEvents.clickOnSlideButton('Previous', currentBrand.title);
	};

	const getHomePageURL = () => {
		const curUrl: string = CAN_USE_DOM ? window.location.href : '';
		const splitedUrl = curUrl.split('/');

		if (splitedUrl.length === 4) {
			history.push(ROOT_ROUTE);
		} else {
			history.push(GROUP_OF_BRANDS_PAGE_ROUTE.replace(':groupId', groupUrl));
		}

		if (!IS_DEVEL) dataLayerEvents.clickOnSlideButton('Go Back To Main', currentBrand.title);
	};

	const getLocalItemIsLike = () => {
		if (CAN_USE_DOM) {
			return window.localStorage.getItem(currentBrand.id);
		}
	};

	const updateLike = async () => {
		if (!getLocalItemIsLike()) {
			if (CAN_USE_DOM) {
				window.localStorage.setItem(currentBrand.id, 'true');
			}
			const isUpdatedLikes = await updateLikes({
				collection: COLLECTIONS.BRANDS,
				data: { id: currentBrand.id, addLike: true },
			});

			setLikes(likes + 1);

			if (isUpdatedLikes.error) {
				if (CAN_USE_DOM) {
					window.localStorage.removeItem(currentBrand.id);
				}
				setLikes(likes - 1);
			} else if (!IS_DEVEL) dataLayerEvents.clickOnLikeIcon(currentBrand.title);
		} else {
			if (CAN_USE_DOM) {
				window.localStorage.removeItem(currentBrand.id);
			}
			const isUpdatedLikes = await updateLikes({
				collection: COLLECTIONS.BRANDS,
				data: { id: currentBrand.id, addLike: false },
			});

			setLikes(likes - 1);

			if (isUpdatedLikes.error) {
				if (CAN_USE_DOM) {
					window.localStorage.setItem(currentBrand.id, 'true');
				}
				setLikes(likes + 1);
			} else if (!IS_DEVEL) dataLayerEvents.clickOnLikeIcon(currentBrand.title);
		}
	};

	const renderHelmet = () => {
		const title = _.get(currentBrand, 'seoTitle', '');
		const description = _.get(currentBrand, 'seoDescription', '');

		const image = _.get(currentBrand, 'shareImage');
		return (
			<Helmet
				title={title}
				link={[{ rel: 'icon', type: 'image/png', href: faviconImg }]}
				meta={[
					{
						name: 'description',
						content: description,
					},
					{ property: 'og:title', content: title },
					{ property: 'og:description', content: description },
					{ property: 'og:image', content: fileLink(image) },
				]}
			/>
		);
	};

	return (
		<div className={cn(css.page, css[transitionState])}>
			{renderHelmet()}
			<Header isHideSocialls withoutShadow />
			<SidePanel />

			{_.map(neededSlides, (item, index) => {
				return (
					<div key={item.id}>
						{slideIndex === index && (
							<>
								<Slide
									item={item}
									slidesSize={_.size(neededSlides)}
									brand={currentBrand}
									nextSlide={nextSlide}
									previousSlide={previousSlide}
									isWelcome={isWelcome}
									slideIndex={slideIndex}
									goToHomepage={getHomePageURL}
									likes={likes}
									updateLike={updateLike}
								/>
							</>
						)}
					</div>
				);
			})}
		</div>
	);
};

export default withTransition(BrandPage);
